
.Home {
    text-align: center;
    font-family: "Nunito", sans-serif;
    position: relative;
  }

  .Landing-Page {
    position: relative;
  }
  
  .Landing-Page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* White with 80% opacity */
    z-index: 1;
  }
  
  .Content-Coming-Soon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 100px;
    font-weight: bold;
    color: black;
    /* text-shadow: 2px 2px 0 #7BB6E7; */
    z-index: 2;
  }

  .Home-logo {
    width: 20vw; /* Adjust as needed */
    height: auto; /* Adjust as needed */
    margin-bottom: 40px; /* Adjust as needed */
    border-radius: 50%;
  }

  .Main-title {
    color: white;
    margin-bottom: 10px;
  }

  .Intro-h2 {
    margin: 0 0 40px 0;
    font-size: 2.5rem;
  }
  
  .Home-header {
    background-color: #f5ede4;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    font-size: calc(10px + 3vmin);
    /* color: #7BB6E7; */
    position: relative;
  }

  .Home-photo {
    width: 100%;
    max-width: 95vw;
    height: 95vh;
    margin-top: 1%;
    border-radius: 5px;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2); /* Add box shadow */
  }

  .Home-nav {
    /* position: fixed; */
     /* Set position to fixed to keep it fixed while scrolling */
    /* top: 25px;  */
    /* Adjust distance from the top */
    /* right: 40px;s */
     /* Adjust distance from the right */
    display: flex;
    flex-direction: column; /* Arrange buttons vertically */
    align-items: center; /* Align buttons horizontally */
    /* z-index: 999;  */
    /* Ensure it stays on top of other content */
  }
  
  .Home-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white; /* Optionally, change text color */
  }
  
  .home-p {
    /* color: #7BB6E7;*/
    color: white;
  }
  
  .Home-link {
    /* color: #7BB6E7; */
    color: white;
    /* text-decoration: none; */
  }

  .Home-link:hover {
    /* transform: scale(1.1);
    transition: transform 0.3s ease-in-out; */
    /* color: #7BB6E7; */
    /* color: #79b99a; */
    color: #d4ba8b;
  }

  .Nav-container {
    position: fixed;
    top: 25px;
    right: 40px;
    background-color: rgba(128, 128, 128, 0.4); /* Opaque background */
    border-radius: 30px; /* Rounded corners */
    padding: 15px 10px 5px 10px; /* Adjust padding as needed */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add box shadow */
    z-index: 999;
  }

  .Nav-button {
    color: #7BB6E7;
    font-family: "Nunito", sans-serif;
    font-weight: bold;
    font-size: 1rem;
    text-decoration: none;
    padding: 0 7.5px; /* Adjust padding as needed */
    background-color: #f5ede4;
    border: none; /* Remove button border */
    border-radius: 50%; /* Rounded corners */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
    /* display: block; */
    margin-bottom: 10px; /* Adjust margin between buttons */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add box shadow */
    opacity: 0.8;
  }

  .Nav-button:hover {
    background-color: #7BB6E7; /* Darker background color on hover */
    color: #f5ede4; /* Change text color on hover */
    transform: scale(1.15);
  }
  

  @media only screen and (max-width: 768px) {

    .Main-title {
      font-size: 2rem; /* Adjust the font size of the h1 */
      margin-bottom: 3rem;
    }

    .Home-photo {
      margin-top: 0.75rem;
      width: 100%;
      object-fit: cover; /* Ensure the image covers the container without stretching */
    }

    .Home-logo {
      width: 50vw; /* Increase the width of the logo */
      height: auto; /* Maintain aspect ratio */
      border-radius: 50%; /* Keep the border-radius for circular shape */
    }
  
    .Intro-h2 {
      font-size: 2rem; /* Adjust the font size of the h2 */
      margin-bottom: 2rem;
    }
  
    .home-p {
      font-size: 1.5rem; /* Adjust the font size of the p */
    }
  
    .Nav-container {
      right: 12.5%;
    }
  
  }
  
  @media only screen and (max-width: 480px) {
    .Main-title {
      font-size: 2rem; /* Further reduce font size of the h1 */
      margin-top: 0;
    }

    .Home-photo {
      margin-top: 0.75rem;
      width: 100%;
      object-fit: cover; /* Ensure the image covers the container without stretching */
    }

    /* .Home-logo {
      height: 20vh;
    } */
  
    .Intro-h2 {
      font-size: 1.5rem; /* Further reduce font size of the h2 */
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    .Content-Coming-Soon {
      font-size: 65px;
      z-index: 1000;
    }
  
    .home-p {
      font-size: 1rem; /* Further reduce font size of the p */
    }
  

  }
